<template>
  <div class="w-block__footer">
    <!-- footer block -->
    En partenariat avec le cabinet Horizon Public Conseils et AirZen Radio.
    <div class="mt-1 mb-4 list-footer-images">
      <img
        :src="require('~/assets/images/logo-hpc.png')"
        height="30"
        alt="logo-hpc"
        class="mr-4"
      />
    </div>
    <img
      :src="require('~/assets/images/logo-airzen.svg')"
      height="40"
      alt="logo-airzen radio"
      class="mr-4"
    />
    <exc-dialog />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
  },
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.list-footer-images {
  display: flex !important;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap !important;
}
</style>
