<template>
  <div
    class="w-block__post-featured"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- post-featured block -->
    <v-container v-if="isHomeActive" class="pa-0">
      <v-sheet
        ref="sheet"
        color="#fff5dc"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">
            Laissez un message sur notre répondeur !
          </div>
          <v-btn
            ref="btn"
            color="#cc99ff"
            rounded
            depressed
            class="ma-2 white--text"
            @click.stop="showFeedbackModal = true"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-microphone </v-icon>
            Enregistrer mon message
          </v-btn>
          <w-feedback-popup id="firstFeedback" v-model="showFeedbackModal" />
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__post-featured {
  justify-content: center;
  text-align: center;
  font-weight: bold;

  .v-sheet {
    text-align: left;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.body-2 {
  text-align: center;
}
</style>
