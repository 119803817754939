const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5e8f1e1e161766069ca55f46',
    enableExperimentalBlocks: false,
    authMode: ['local'],
    authLocalOptions: {
      mode: 'otp',
    },
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'Challenge de la mobilité',
      colorPrimary: '#ff3557',
    },
    analytics: {
      matomo: '4',
    },
    useShell: [
      'bb-default',
      {
        mode: 'list',
      },
    ],
    drawerNavigation: () => [
      {
        type: 'method',
        icon: 'mdi-message-processing',
        value: '/settings',
        title: 'Campagne SMS',
        onClick: (context) => {
          context.formModal = true
        },
      },
      {
        type: 'link',
        icon: 'mdi-cog-outline',
        value: '/settings',
        title: (ctx) => ctx.$t('glob_sidebar_settings'),
      },
      {
        icon: 'mdi-information-outline',
        value: 'info',
        title: (ctx) => ctx.$t('glob_sidebar_moreabout'),
        noAction: true,
        disableAutoClose: true,
        items: [
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_legals`),
            value: '/data/legal-notice',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_terms`),
            value: '/data/terms-of-service',
          },
          {
            type: 'link',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_data`),
            value: '/data/personal-data',
          },
          {
            type: 'method',
            title: (ctx) => ctx.$t(`glob_sidebar_moreabout_versions`),
            value: 'versions',
            onClick: (context) => {
              context.dialogVersions = true
            },
          },
        ],
      },
      {
        type: 'link',
        icon: 'mdi-help-circle-outline',
        title: (ctx) => ctx.$t(`glob_sidebar_support`),
        value: '/support',
      },
      {
        condition(context) {
          return context.$spoke.site.$data('optAuthentication') === true
        },
        type: 'method',
        icon: 'mdi-exit-to-app',
        value: 'logout',
        title: (ctx) => ctx.$t('glob_sidebar_exit'),
        onClick: async (context) => {
          await context.$store.dispatch('auth/logout')
        },
      },
    ],
    WFeedBack: {
      // ↓↓↓ recorder name / ID (for multiple recorder) ↓↓↓
      firstFeedback: {
        title: 'Enregistrez votre dédicace',
        name: 'Dédicaces audio',
        type: 'audio',
        maxDuration: 15,
        headCaption: `Laissez votre dédicace ! Elle sera diffusée à l'antenne demain entre 12h et 13h`,
        subCaption: `Je suis informé que ce site traite les données recueillies pour permettre la diffusion de votre dédicace. Pour en savoir plus, reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Votre message a bien été envoyé.`,
        pseudonym: {
          label: 'Nom ou pseudo (facultatif)',
          placeholder: 'Mimie Mathy',
        },
        recorderForm: [
          {
            label: 'Adresse mail',
            placeholder: 'mimie@mathy.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: 'Tu préfères',
            items: ['Manger', 'Boire', 'Dormir'],
            value: '',
            type: 'select',
            required: true,
          },
          {
            label: 'Numéro de téléphone',
            placeholder: '01 23 45 67 89',
            value: '',
            type: 'phone-number',
            required: false,
          },
          {
            label: 'Pour ou contre',
            value: false,
            type: 'switch',
          },
          {
            label: "J'accepte que mon message soit diffusé dans Radio DEMO",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
      secondFeedback: {
        title: 'Formulaire quotidien de participation',
        name: 'kilometres-quotidien',
        type: 'form',
        headCaption: `Tous les jours, complétez le formulaire de participation au challenge !`,
        subCaption: `Je suis informé que ce site traite les données recueillies pour vous permettre de participer au challenge. Pour en savoir plus, reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Vos kilomètres du jour ont bien été envoyés.`,
        pseudonym: {
          label: 'Nom ou pseudonyme (facultatif)',
          placeholder: 'Le pédaleur masqué',
        },
        recorderForm: [
          {
            label: `Combien de kms parcourus aujourd'hui ?`,
            placeholder: '370 000',
            value: '',
            type: 'text-field',
            required: true,
          },
          {
            label: `Quelle est l'alternative du jour ?`,
            items: [
              'Vélo',
              'Marche',
              'Tramway',
              'Bus',
              'Car',
              'Covoiturage',
              'Train (Régional ou TGV)',
              'Trotinette, One Wheel, Skate, Roller',
              'Télétravail',
            ],
            value: '',
            type: 'select',
            required: true,
          },
          {
            label: "J'accepte l'utilisation de mes données personnelles",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
